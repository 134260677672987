import * as React from "react"

const NotFoundPage = () => {
  return (
    <main className="mx-auto max-w-xs">
      <h1 className="text-4xl fond-bold">Page not found</h1>
    </main>
  )
}

export default NotFoundPage
